import React, { useEffect } from 'react'
import Idos from './Idos';
import { gql, useQuery } from '@apollo/client';

const ALL_IDOS = gql`

query {
      allIdos{
        id
        productLogo
        productName
        price
        description
        tokenAddress
        contractAddress
        images
        vestingSchedule
        status
        listed
        metaData
        
      }
    }

`;

function Ido() {

  const { data } = useQuery(ALL_IDOS);
  useEffect(() => {


    return () => {

    }
  }, [data])

  return (
    <div>
      {data && <Idos data={data} />}
    </div>
  )
}

export default Ido