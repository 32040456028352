import React from 'react'
import EditIdo from './EditIdo'
import { gql, useQuery } from '@apollo/client';


const ALL_IDOS = gql`

query IDO($id:String!) {
      ido(id:$id){
        id
        productLogo
        productName
        price
        description
        tokenAddress
        contractAddress
        images
        vestingSchedule
        status
        listed
        metaData
        
      }
    }

`;

function Editido() {
    console.log(window.location.pathname.split("/"), "hii");
    const id = window.location.pathname.split("/")[3];

    const { data } = useQuery(ALL_IDOS, {
        variables: { id }
    })


    return (
        <div>
            {data && <EditIdo data={data} />}
        </div>
    )
}

export default Editido