import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { create } from "ipfs-http-client";
import factorAbi from '../utils/idofactory.json'

import {
    Card,
    Table,
    TextField,
    IconButton,
    Divider,
    InputAdornment,
    Stack,
    Input,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TextareaAutosize,
} from "@mui/material";
import { LoadingButton, DatePicker } from "@mui/lab";

// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";
import {
    UserListHead,
    UserListToolbar,
    UserMoreMenu,
} from "../../components/_dashboard/user";
//
import USERLIST from "../../_mocks_/user";
import { ipfsClient, uploadImageToIpfs } from "../../ipfs";
import { image } from "faker";
import Web3 from "web3";
import { useWallet } from "use-wallet";
import { gql, useMutation, useQuery } from '@apollo/client';


const CREATE_IDO = gql`
  mutation UpdateIdo(
    $id:String!
    $productLogo: String
    $description: String
    $images: String
    $status: String
    $listed: Boolean
    $metaData: String
  ){
    updateIdo(id:$id,ido:{
    productLogo:$productLogo,
    description:$description,
    images:$images,
    status:$status,
    listed:$listed,
    metaData:$metaData,
  }){
    id
    price
    productName
    description
  }
  }
`

const ImageInput = ({ title, src, onChange, height, width }) => {
    const [image, setImage] = useState(src);
    const [isLoading, setLoading] = useState(false);

    return (
        <div>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {title}
            </Typography>

            {isLoading ? (
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                <>
                    {image ? <img src={image} height={height} width={width} /> : null}
                    <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={async (e) => {
                            // const reader = new FileReader(), files = e.target.files;
                            const file = e.target.files[0];
                            setLoading(true);
                            const { path } = await uploadImageToIpfs(file);
                            console.log(path);
                            // setImage(file);
                            onChange(path);
                            setLoading(false);
                            // reader.onload = function () {
                            //   // setImage(img);
                            //   setImage(reader.result);
                            //   onChange(reader.result);
                            // };
                            // reader.readAsDataURL(files[0]);
                            // console.log(reader.result);
                            // reader.readAsDataURL(files[0]);
                            // console.log(reader.readAsDataURL(files[0]));
                            // console.log(URL.createObjectURL(file));

                        }}
                    />
                </>
            )}
        </div>
    );
};

const DescriptionsInput = ({
    onHeadingChange,
    onContentChange,
    onImageChange,
    title,
    name,
    description
}) => {
    const [isLoading, setLoading] = useState(false);
    // console.log(description[name],name,description);
    return (
        <>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {title}
            </Typography>

            {description && <TextField
                fullWidth
                // minRows={1}
                label="Enter Title"
                value={description && description[name].heading}
                onChange={(e) => {
                    onHeadingChange(e.target.value);
                }}
            />}

            <TextField
                fullWidth
                style={{ marginTop: 10 }}
                mt={10}
                minRows={10}
                label="Enter Content"
                value={description && description[name].description}

                // value={description[name].description}
                onChange={(e) => {
                    onContentChange(e.target.value);
                }}
            />

            <input
                type="file"
                style={{ marginTop: 10, marginBottom: 20 }}
                accept="image/png, image/jpeg"

                onChange={async (e) => {
                    const file = e.target.files[0];
                    // setLoading(true);
                    // // const { path } = await uploadImageToIpfs(file);
                    // setLoading(false);
                    // onImageChange(file);
                    // const reader = new FileReader(), files = e.target.files;
                    // const file = e.target.files[0];
                    setLoading(true);
                    const { path } = await uploadImageToIpfs(file);
                    onImageChange(path);
                    setLoading(false);
                    // reader.onload = function () {
                    //   // setImage(img);
                    //   // setImage(reader.result);
                    //   // onImageChange(reader.result);
                    // };
                    // reader.readAsDataURL(files[0]);
                    // console.log(reader.result);
                }}
            />
        </>
    );
};
export default function EditIdo(props) {


    const [updateIdo, { dataIdo, loadingIdo, errorIdo }] = useMutation(CREATE_IDO);


    const renderButton = () => {
        // if (wallet.status === "connected") {
            return (
                <LoadingButton onClick={() => { UploadFile() }} fullWidth size="large" type="submit" variant="contained">
                    Update Ido
                </LoadingButton>
            )
        // }
        // if (wallet.status === "connected") {
        //   return (
        //     <LoadingButton fullWidth size="large" type="submit" variant="contained">
        //       Loading...
        //     </LoadingButton>
        //   )
        // }
        // else {
        //     return (
        //         <LoadingButton onClick={() => { wallet.connect() }} fullWidth size="large" type="submit" variant="contained">
        //             Unlock Wallet
        //         </LoadingButton>
        //     )
        // }
    }
    useEffect(() => {

        if (props.data.ido) {
            setProjectMetadata(JSON.parse(props.data.ido.metaData));
            setDescriptions(JSON.parse(props.data.ido.description));
        }
        return () => {

        }
    }, [])
    //meta data///
    const wallet = useWallet();
    const [projectMetadata, setProjectMetadata] = useState({
        name: "",
        logo: "",
        headerImage: "",
        tokenAddress: "",
        socialMedias: {
            facebook: "",
            twitter: "",
            medium: "",
            telegram: "",
        },
    });

    const [descriptions, setDescriptions] = useState({
        product: {
            heading: "",
            description: "",
            image: "",
        },

        problem: {
            heading: "",
            description: "",
            image: "",
        },

        solution: {
            heading: "",
            description: "",
            image: "",
        },

        businessModal: {
            heading: "",
            description: "",
            image: "",
        },

        market: {
            heading: "",
            description: "",
            image: "",
        },
        investor: {
            heading: "",
            description: "",
            image: "",
        },
        team: {
            heading: "",
            description: "",
            image: "",
        },

        utility: {
            heading: "",
            description: "",
            image: "",
        },
    });

    const [presaleInfo, setPresaleInfo] = useState({
        startDate: "",
        endDate: "",
        pricePerToken: "",
        investToken: "",
        tokensForSale: "",
        maxContribute: "",
        minContribute: "",
        minimumRaise: "",
        feePercent: "",
    });


    const entryInContract = async (hash) => {

        // const web3 = new Web3(Web3.givenProvider);

        // let contract = await new web3.eth.Contract(factorAbi, "0x38Fd9614a60814C2BaC69AC38d1f6522e8e68E56")//TOKEN_IDO_ABI
        // console.log(contract, "contract");

        // const startDate = new Date(presaleInfo.startDate).getTime() / 1000;
        // const endDate = new Date(presaleInfo.endDate).getTime() / 1000;

        // // addToBackend()
        // const tx = await contract.methods.createIDO(hash, projectMetadata.tokenAddress, presaleInfo.pricePerToken, presaleInfo.tokensForSale, startDate, endDate, presaleInfo.minContribute, presaleInfo.maxContribute, true, presaleInfo.minimumRaise, presaleInfo.feePercent, false).send({ from: wallet.account });
        // console.log(tx, "transaction");
        // addToBackend(tx.events.IdoCreated.returnValues[0])

    }


    const addToBackend = async () => {
        updateIdo({
            variables: {
                id:window.location.pathname.split("/")[3],
                productLogo: "", /// projectMetadata.logo
                description: JSON.stringify(descriptions),
                images: "",
                status: "true",
                listed: true,
                metaData: JSON.stringify(projectMetadata),
            }
        })
    }


    const UploadFile = async () => {

        const obj = {
            presaleInfo,
            projectMetadata,
            descriptions
        }

        addToBackend();
        // if (projectMetadata.tokenAddress && projectMetadata.name && presaleInfo.feePercent && presaleInfo.minContribute && presaleInfo.maxContribute && presaleInfo.pricePerToken && presaleInfo.startDate && presaleInfo.endDate && presaleInfo.minimumRaise && presaleInfo.tokensForSale) {
        //     const readyJson = JSON.stringify(obj);
        //     console.log(readyJson);
        //     const file = Buffer.from(readyJson)
        //     const { path } = await ipfsClient.add(file);
        //     console.log(path);
        //     entryInContract(path);
        // }
    }


    // console.log(entryInContract(),"log");



    return (
        <Page title="Create IDO">
            <Container>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Create IDOS
                    </Typography>
                </Stack>

                <Divider sx={{ my: 3 }}>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Project Info
                    </Typography>
                </Divider>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <ImageInput
                        title="Choose Project Logo"
                        onChange={(img) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                logo: img,
                            });
                        }}
                        width={150}
                        height={150}

                    />

                    <ImageInput
                        title="Choose Project Header"
                        onChange={(img) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                headerImage: img,
                            });
                        }}
                    />
                </Stack>

                <Stack
                    mb={5}
                    mt={5}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                >
                    <TextField
                        fullWidth
                        label="Project Name"
                        value={projectMetadata.name}

                        onChange={(e) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                name: e.target.value,
                            });
                        }}
                    />

                    <TextField
                        fullWidth
                        minRows={10}
                        label="Project Token Address"
                        value={projectMetadata.tokenAddress}
                        disabled
                        onChange={(e) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                tokenAddress: e.target.value,
                            });
                        }}
                    />
                </Stack>

                <Stack
                    mb={5}
                    mt={5}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                >
                    <TextField
                        fullWidth
                        value={projectMetadata.socialMedias.facebook}
                        label="Facebook"
                        onChange={(e) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                socialMedias: {
                                    ...projectMetadata.socialMedias,
                                    facebook: e.target.value,
                                },
                            });
                        }}
                    />

                    <TextField
                        fullWidth
                        label="Telegram"
                        value={projectMetadata.socialMedias.telegram}
                        onChange={(e) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                socialMedias: {
                                    ...projectMetadata.socialMedias,
                                    telegram: e.target.value,
                                },
                            });
                        }}
                    />

                    <TextField
                        fullWidth
                        label="Medium"
                        value={projectMetadata.socialMedias.medium}
                        onChange={(e) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                socialMedias: {
                                    ...projectMetadata.socialMedias,
                                    medium: e.target.value,
                                },
                            });
                        }}
                    />

                    <TextField
                        fullWidth
                        label="Twitter"
                        value={projectMetadata.socialMedias.twitter}
                        onChange={(e) => {
                            setProjectMetadata({
                                ...projectMetadata,
                                socialMedias: {
                                    ...projectMetadata.socialMedias,
                                    twitter: e.target.value,
                                },
                            });
                        }}
                    />
                </Stack>

                <Divider sx={{ my: 3 }}>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        Descriptions
                    </Typography>
                </Divider>

                <Stack>
                    <DescriptionsInput
                        title={"Product"}
                        name="product"
                        description={descriptions}
                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                product: {
                                    ...descriptions.product,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                product: {
                                    ...descriptions.product,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                product: {
                                    ...descriptions.product,
                                    description: text,
                                },
                            });
                        }}
                    />

                    <DescriptionsInput
                        title={"Problem"}
                        name="problem"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                problem: {
                                    ...descriptions.problem,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                problem: {
                                    ...descriptions.problem,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                problem: {
                                    ...descriptions.problem,
                                    description: text,
                                },
                            });
                        }}
                    />

                    <DescriptionsInput
                        title={"Solution"}
                        name="solution"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                solution: {
                                    ...descriptions.solution,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                solution: {
                                    ...descriptions.solution,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                solution: {
                                    ...descriptions.solution,
                                    description: text,
                                },
                            });
                        }}
                    />
                    <DescriptionsInput
                        title={"BusinessModal"}
                        name="businessModal"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                businessModal: {
                                    ...descriptions.solution,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                businessModal: {
                                    ...descriptions.solution,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                businessModal: {
                                    ...descriptions.solution,
                                    description: text,
                                },
                            });
                        }}
                    />
                    <DescriptionsInput
                        title={"Market"}
                        name="market"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                market: {
                                    ...descriptions.solution,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                market: {
                                    ...descriptions.solution,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                market: {
                                    ...descriptions.solution,
                                    description: text,
                                },
                            });
                        }}
                    />
                    <DescriptionsInput
                        title={"Team"}
                        name="team"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                team: {
                                    ...descriptions.solution,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                team: {
                                    ...descriptions.solution,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                team: {
                                    ...descriptions.solution,
                                    description: text,
                                },
                            });
                        }}
                    />
                    <DescriptionsInput
                        title={"Investors"}
                        name="investor"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                investor: {
                                    ...descriptions.solution,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                investor: {
                                    ...descriptions.solution,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                investor: {
                                    ...descriptions.solution,
                                    description: text,
                                },
                            });
                        }}
                    />
                    <DescriptionsInput
                        title={"Utility"}
                        name="utility"
                        description={descriptions}

                        onImageChange={(img) => {
                            setDescriptions({
                                ...descriptions,
                                utility: {
                                    ...descriptions.solution,
                                    img: img,
                                },
                            });
                        }}
                        onHeadingChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                utility: {
                                    ...descriptions.solution,
                                    heading: text,
                                },
                            });
                        }}
                        onContentChange={(text) => {
                            setDescriptions({
                                ...descriptions,
                                utility: {
                                    ...descriptions.solution,
                                    description: text,
                                },
                            });
                        }}
                    />
                </Stack>






                {/* <Divider sx={{ my: 3 }}>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        IDO Information
                    </Typography>
                </Divider> */}



                {/* <Stack
          mb={5}
          mt={5}
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Token Address"
            onChange={(e) => {
              setPresaleInfo({...presaleInfo,tokenAddress:e.target.value})
            }}
          />
        </Stack> */}


                {/* <Stack
                    mb={5}
                    mt={5}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                >
                    <label style={{ width: "100%" }}>
                        Start Date
                        <Input
                            type="date"
                            fullWidth
                            label="Start Date"
                            placeholder="Start Date"
                            onChange={(e) => {
                                setPresaleInfo({ ...presaleInfo, startDate: e.target.value })
                            }}
                        />
                    </label>


                    <label style={{ width: "100%" }}>
                        End Date
                        <Input
                            fullWidth
                            label="End Date"
                            type="date"
                            onChange={(e) => {
                                setPresaleInfo({ ...presaleInfo, endDate: e.target.value })
                            }}
                        />
                    </label>


                </Stack>
                <Stack
                    mb={5}
                    mt={5}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                >
                    <TextField
                        fullWidth
                        label="Price"
                        onChange={(e) => {
                            setPresaleInfo({ ...presaleInfo, pricePerToken: e.target.value })
                        }}
                    />


                    <TextField
                        fullWidth
                        label="Token for sale"
                        onChange={(e) => {
                            setPresaleInfo({ ...presaleInfo, tokensForSale: e.target.value })
                        }}
                    />


                </Stack>
                <Stack
                    mb={5}
                    mt={5}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                >
                    <TextField
                        fullWidth
                        label="Individual Minimum Amount"
                        onChange={(e) => {
                            setPresaleInfo({ ...presaleInfo, minContribute: e.target.value })
                        }}
                    />


                    <TextField
                        fullWidth
                        label="Individual Maximum Amount"
                        onChange={(e) => {
                            setPresaleInfo({ ...presaleInfo, maxContribute: e.target.value })
                        }}
                    />


                </Stack>

                <Stack
                    mb={5}
                    mt={5}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                >
                    <TextField
                        fullWidth
                        label="Minimum Raise"
                        onChange={(e) => {
                            setPresaleInfo({ ...presaleInfo, minimumRaise: e.target.value })
                        }}
                    />


                    <TextField
                        fullWidth
                        label="Fee Amount"
                        onChange={(e) => {
                            setPresaleInfo({ ...presaleInfo, feePercent: e.target.value })
                        }}
                    />


                </Stack> */}

                {/* <Stack
          mb={5}
          mt={5}
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
        >
          <TextField
            fullWidth
            label="Minimum Raise"
            onChange={(e) => {
              setProjectMetadata({
                ...projectMetadata,
                socialMedias: {
                  ...projectMetadata.socialMedias,
                  medium: e.target.value,
                },
              });
            }}
          />


          <TextField
            fullWidth
            label="Fee Amount"
            onChange={(e) => {
              setProjectMetadata({
                ...projectMetadata,
                socialMedias: {
                  ...projectMetadata.socialMedias,
                  medium: e.target.value,
                },
              });
            }}
          />


        </Stack> */}
                {renderButton()}
                {/* { <Button variant="contained" onClick={() => {
          UploadFile()
        }}>
          Create Ido
        </Button>} */}

            </Container>
        </Page>
    );
}
